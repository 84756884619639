//Additional offset for sections for the Hash-Scrolling/Secondary Menu
//Each section has a <a class="ubf-anchor"> before for the secondary menu section navigation
.ubf-anchor {
	display: block;
	position: relative;
	top: -$headerHeightSmall;
	visibility: hidden;

	@include media($largeLayoutBreakpoint) {
		top: -$menuHeight - $base2;
	}

	// align it on top since toggler is flex row
	.ubf-accordion__toggler & {
		align-self: flex-start;
	}
}
