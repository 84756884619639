.ubf-dont-hyphenate {
	hyphens: manual;
}

/* vb: 03.02.2021: Helper styles for roxen backend/link picker component */

/* Fixing broken Edit-Buttons */
.roxen-edit-box-wrap .editlink, a.roxen-linkbrowser {
	height: auto !important;
	z-index:5!important;
	color: #fff!important;
	font-weight: bold!important;
	text-decoration: none!important;
}
.roxen-edit-box-wrap .ubf-ugc.ubf-preview__summary {
	border:none!important;
}
.editlink {
	z-index: 99999 !important;
}

a:hover {
	color: inherit!important;
}
