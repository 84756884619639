.ubf-mainMenuToggler {
	@mixin line() {
		backface-visibility: hidden;
		background-color: currentColor;
		height: $menuTogglerLineSize;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	color: currentColor;
	@include ubf-outlineOnFocus();

	// &__canvas {
	// 	position: absolute;
	// 	top: 0;
	// 	right: 0;
	// 	width: 25px;
	// 	height: 50px;
	// 	z-index: 100;
	// }


	&__label {
		@include line();
		@include ease(background-color, $duration2);
		font-size: 0;
		text-indent: -10000px;
		pointer-events: none;
		transform: translateY(#{$menuTogglerLineSpace + $menuTogglerLineSize});

		.ubf-mainMenuToggler.ubf-js-toggled &,
		.ubf-mainMenuToggler--close & {
			background-color: transparent;
		}
		width: $menuTogglerWidth;

		&::before {
			@include line();
			@include ease(transform, $duration2);
			content: '';
			transform: translateY(-#{$menuTogglerLineSpace + $menuTogglerLineSize});

			.ubf-mainMenuToggler.ubf-js-toggled &,
			.ubf-mainMenuToggler--close & {
				transform: translateY(0) rotate(45deg);
			}
		}


		&::after {
			@include line();
			@include ease(transform, $duration2);
			content: '';
			transform: translateY(#{($menuTogglerLineSpace + $menuTogglerLineSize)});

			.ubf-mainMenuToggler.ubf-js-toggled &,
			.ubf-mainMenuToggler--close & {
				transform: translateY(0) rotate(-45deg);
			}
		}
	}
}
