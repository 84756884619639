@mixin ubf-coverImg() {
	bottom: -$menuHeight; //height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;

	&::after {
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		background-image: linear-gradient(-6deg, transparentize($colorCoverGradientDark, 0.2), transparentize($colorCoverGradientDark, 1) 25%, transparentize($colorCoverGradientLight, 1) 55%, transparentize($colorCoverGradientLight, 0.4), 100%, transparentize($colorCoverGradientLight, 0.4));
		z-index: 1;

		@include media($largeLayoutBreakpoint) {
			background-image: linear-gradient(-6deg, transparentize($colorCoverGradientDark, 0.2), transparentize($colorCoverGradientDark, 1) 50%, transparentize($colorCoverGradientLight, 1) 55%, transparentize($colorCoverGradientLight, 0.3), 100%, transparentize($colorCoverGradientLight, 0.3));
		}
	}
}
