.ubf-slideshowNavigation {
	.ubf-section--cover & {
		padding-bottom: $base3;

		@include media($largeLayoutBreakpoint) {
			padding-bottom: $base5;
		}
	}
	.ubf-gallery__slideshow & {
		z-index:100;

		& .ubf-slideshowNavigation__item--prev .ubf-slideshowNavigation__link {
			justify-content: flex-start;
		}
		& .ubf-slideshowNavigation__item--next .ubf-slideshowNavigation__link {
			justify-content: flex-end;
		}
	}
	.ubf-contentBlock--videoreel & {
		margin-top: 0;
	}

	&__progress {
	  @include ubf-highlightColor(--fg);
	  --pgPercentage: var(--progress);
	  position: absolute;
	  top:-2px;
	  left: -2px;
	  width: 20px;
	  height: 20px;
	  border-radius: 50%;
	  display: grid;
	  place-items: center;
	  clip-path: circle(10px at center);
	  -webkit-mask-image: radial-gradient(circle at center, transparent 54%, black 54.8%);
	  mask-image: radial-gradient(circle at center, transparent 54%, black 54.8%);
	  background: conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), transparent 0);
	}
	&__icon {
		@include ease(background-color, $duration2);
		@include ubf-highlightColor(color);
		border: $boxBorderSize solid currentColor;
		display: inline-block;
		height: $galleryNavigationIconSize;
		position: relative;
		width: $galleryNavigationIconSize;
		pointer-events: none;

		.ubf-body:not(.ubf-body--faculty) & {
			color: $colorHighlightBase;
		}

		.ubf-slideshowNavigation__item--prev & {
			border: 0;
			transform: rotate(180deg);
			[data-type="svg"] {
				display: inline-block;
			}
		}

		.ubf-slideshowNavigation__item--next & {
			border: 0;
		  	[data-type="svg"] {
				display: inline-block;
		  	}
		}

		.ubf-slideshowNavigation__link.ubf-js-current & {
			background-color: currentColor;
		}

		[data-type="svg"] {
		  	height: 100%;
		  	width: auto;
		  	display: none;
		}

	  	&--play {
		  border-color: transparent;
		  animation: flash 1s ease-out;

		  [data-type="svg"] {
			display: inline-block;
		  }
		  fill: currentColor;
		  display: none;
		  .ubf-js-autoplaying.ubf-js-autoplayingPaused  & {
			display: inline-block;
		  }
		}
	  &--pause {
		border-color: transparent;
		animation: flash 1s ease-out;
		[data-type="svg"] {
		  display: inline-block;
		}
		fill: currentColor;
		display: inline-block;
		.ubf-js-autoplaying.ubf-js-autoplayingPaused  & {
		  display: none;
		}
	  }
	}
	@keyframes flash {
	  0% {
		//border-color: #fff;
		scale: 150%;
	  }
	  100% {
		//border-color: currentColor;
		scale: 100%;
	  }
	}

	&__item {
		line-height: 0;
		margin-right: $base2;
		pointer-events: none;
		flex-grow: 1;

		&:last-child {
			margin-right: 0;
		}

		.ubf-section--cover & {
			margin: 0;
		}

		&--prev,
		&--next {
			html.no-js & {
				display: none;
			}
		}
		&--playpause {
		  display: none;
		}
		[data-ubf-autoplay="true"] &--playpause {
		  display: block;
		  .ubf-slideshowNavigation__link .ubf-slideshowNavigation__icon {
			border-radius: 10px;
			height: 20px;
			width: 20px;
			padding: 3px;
		  }
		}

	}


	&__items {
		display: flex;
		flex-direction: row;
		justify-content: center;
		pointer-events: none;

		.ubf-section--cover & {
			justify-content: space-between;
			@include media($largeLayoutBreakpoint) {
				width: staticColumnSize(7, 12, $gridGutterLarge);
			}
		}

		.ubf-modal & {
			pointer-events: none;
			justify-content: space-between;
		}
	}


	&__link {
		align-items: center;
		display: flex;
		justify-content: center;
		min-height: $base3;
		pointer-events: all;
		width: 100%;

		&:not(:hover):focus {
			outline: $focusOutlineStyle $colorInverted 1px;
			outline-offset: $baseHalf;
		}

	}
}
