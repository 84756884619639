// Grid system based on floating elements
// ideal when you don't know in advance how many children you are going to have
// and you need eventually some element to float on the right allowing the other element to stack
// one after each others without vertical gaps
// Features:
// 	- it is left aligned, so you don't need to know in advance the exact layout
// 	- does not need any special style for the last child of the last row
// Limitations:
// 	- it is based on negative margins, so it cannot be directly nested
// 		(a grid cell cannot be directly a grid container, it needs an additional wrapper)
// 	- it does not work well when the container is also having a max-width and horizontal paddings
//
// Alternative: _static-columns.scss
// Alternative: _dynamic-columns.scss

// grid container
@mixin floatColumns($hSpace: false, $vSpace: false, $hSpacePos: left, $vSpacePos: top, $childrenSelector: '> *') {
	@include clearfix();
	position: relative;
	display: block;
	@if ($hSpace != false) {
		margin-#{$hSpacePos}: -$hSpace;
	}
	@if ($vSpace != false) {
		margin-#{$vSpacePos}: -$vSpace;
	}

	@if ($childrenSelector != false) {
		#{unquote($childrenSelector)} {
			float: left;
			@if ($hSpace != false) {
				margin-#{$hSpacePos}: $hSpace;
			}
			@if ($vSpace != false) {
				margin-#{$vSpacePos}: $vSpace;
			}
		}
	}
}
