.ubf-main {
	background-color: $colorBgBase;

	.ubf-body--ekvv & {
		background-color: transparent;
	}

	@media screen and (min-width: 1024px) and (max-width:1199px) {
		margin-top:$base2;
	}

	&.ubf-js-covered {
		position: fixed;
		width: 100%;
		left: 0;
		z-index: 2;

		.ubf-body--ekvv & {
			background-color: $colorEkvvBgBase;
		}

		&[data-ubf-covered-by="menu"] {
			@include media($largeLayoutBreakpoint) {
				left: auto;
				position: static;
				z-index: auto;
			}
		}
	}


	&::before {
		bottom: 0;
		content: '';
		position: absolute;
		width: 100%;
		left: 0;
		top: $paddingTopDefaultSmall;// + #{$base2});
		background-color: $colorBgBase;
		z-index: 2;

		@include media('landscape') {
			top: $paddingTopHomeLarge;
		}

		@include media('landscape', $largeLayoutBreakpoint) {
			top: calc(#{$paddingTopDefaultLarge} - #{$base8});
		}

		.ubf-body--home & {
			@include media('landscape', $largeLayoutBreakpoint) {
				top: calc(#{$paddingTopHomeLarge} - #{$base8});
			}

			html.ubf-js-mobile & {
				@include media('landscape', $largeLayoutBreakpoint) {
					top: calc(#{$paddingTopHomeLargeMobile} - #{$base8});
				}
			}
		}

		.ubf-body--noCover & {
			top: calc(#{$paddingTopNoCoverSmall} - #{$base2});

			@include media($largeLayoutBreakpoint) {
				top: calc(#{$paddingTopNoCoverLarge} - #{$base2});
			}
		}


		.ubf-body--miniCover & {
			top: $paddingTopMiniCover - $base2;

			top: $paddingTopMiniCoverSmall - $base2;

			@include media('landscape') {
				top: $paddingTopMiniCoverSmallLandscape - $base2;
			}

			@include media($largeLayoutBreakpoint) {
				top: $paddingTopMiniCover - $base2;
			}
		}
		.ubf-body--microCover & {
			top: $paddingTopMicroCover - $base2;

			top: $paddingTopMicroCoverSmall - $base2;

			@include media('landscape') {
				top: $paddingTopMicroCoverSmallLandscape - $base2;
			}

			@include media($largeLayoutBreakpoint) {
				top: $paddingTopMicroCover - $base2;
			}
		}

		.ubf-body--ekvv & {
			display: none;
		}
	}


	&[data-ubf-covered-by="menu"]::after {
		background-color: transparent;
		bottom: 0;
		content: '';
		position: fixed;
		width: 100%;
		left: 0;
		top: 0;
		z-index: 10;

		@include media($largeLayoutBreakpoint) {
			display: none;
		}
	}
}
